export const estonian = {
    errorModal: {
        title: "Midagi läks valesti",
        text: "Värskendage lehte või proovige mõne aja pärast uuesti.",
        backButton: "Avalehele"
    },
    familyInvite: {
        errorTitle: 'Viga',
        successTitle: 'Valmis',
    },
    table: {
        asc: "Järjesta kasvavalt",
        desc: "Järjesta kahanevalt",
        searchPlaceholder: "Otsi tulbast..."
    },
    register: {
        title: "Registreeri",
        topInfo: "Iseteenindusse sisenemiseks palun täida enda andmed ja registeeri ennast meie kasutajaks.",
        firstNameField: "Teie eesnimi *",
        lastNameField: "Teie perekonnanimi *",
        personalCodeField: "Teie isikukood *",
        emailField: "Teie e-mail *",
        phoneField: "Teie telefoninumber *",
        emailNewsletter: "Soovin saada Olerexi uudiskirja e-maili teel",
        emailNewsletterMobile: "Olerexi uudiskiri e-mailile",
        emailOffers: "Soovin saada personaalseid sooduspakkumisi e-maili teel",
        emailOffersMobile: "Sooduspakkumised e-mailile",
        smsOffers: "Soovin saada personaalseid sooduspakkumisi SMS-i teel",
        smsOffersMobile: "Sooduspakkumised SMS-i teel",
        backButton: "Tagasi",
        joinButton: "Registreeri",
        bottomText: "Sisselogimisega seotud probleemide korral palume ühendust võtta Olerexi klienditeeninduse või teie personaalse kliendihalduriga.",
        bottomHours: "E-R 8:00 - 18:00",
        errorTitle: "Viga registeerimisel",
    },
    title: {
        "/": "Minu konto | Olerex",
        "/vouchers": "Vautšerid | Olerex",
        "/transactions": "Tehingud | Olerex",
        "/idcredit": "ID-krediit | Olerex",
        "/cards": "Kaardid | Olerex",
        "/invoicing": "Arveldus | Olerex",
        "/invoicing/invoices": "Arved & Laekumised | Olerex",
        "/manage": "Haldus | Olerex",
        "/virtualfamily": "Virtuaalpere | Olerex",
        "/electricity": "Elekter | Olerex",
        "/wholesale": "Hulgimüük | Olerex",
        "/wholesale/orders": "Tellimuste ajalugu | Olerex",
    },
    bankLinks: {
        title: "Vali maksemeetod",
        checkboxText: "Nõustun",
        termsOfSaleLink: "Müügitingimustega",
        termsOfSaleUrl: "https://olerex.ee/olxweb/documents/Terms_of_Sale_Olerex_et.pdf"
    },
    login: {
        title: "ISETEENINDUS",
        titleInfo: "Jätkamiseks valige tuvastusmeetod",
        passwordLogin: "Parool",
        idCardInfo: "Sisestage ID-kaart lugejasse ja vajutage nupul, et jätkata",
        phoneField: "Mobiiltelefoni number",
        personalCodeField: "Isikukood",
        smartId2TimerText: "Turvakood",
        usernameField: "Kasutajatunnus",
        passwordField: "Parool",
        bottomText: "Sisselogimisega seotud probleemide korral palume ühendust võtta Olerexi klienditeeninduse või teie personaalse kliendihalduriga.",
        bottomHours: "E-R 8:00 - 18:00"
    },
    signing: {
        title: "ALLKIRJASTAMINE",
        titleInfo: "Jätkamiseks valige tuvastusmeetod",
        idCardInfo: "Sisestage ID-kaart lugejasse ja vajutage nupul, et jätkata",
        phoneField: "Mobiiltelefoni number",
        personalCodeField: "Isikukood",
        smartId2TimerText: "Turvakood",
        bottomText: "Allkirjastamisega seotud probleemide korral palume ühendust võtta Olerexi klienditeeninduse või teie personaalse kliendihalduriga.",
        bottomHours: "E-R 8:00 - 18:00"
    },
    joinVirtualFamily: {
        topInfo: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt in ligula id faucibus. Duis malesuada sagittis dignissim.Sed dui turpis, euismod et sodales sit amet, feugiat eget est. Pellentesque finibus interdum nibh sed interdum. Integer ut massa a velit euismod hendrerit quis quis velit. Cras lectus odio, rhoncus ac elementum non, interdum vitae justo.",
        nameField: "Teie ees- ja perekonnanimi",
        personalCodeField: "Teie isikukood",
        emailField: "Teie e-mail",
        checkboxInfo: "Nõustun, et cras lectus odio, rhoncus ac elementum non, vitae justo. Duis malesuada sagittis dignissim.",
        backButton: "Tagasi",
        joinButton: "Kinnita andmed ja liitu virtuaalperega"
    },
    filters: {
        title: "FILTRID"
    },
    navbar: {
        overview: "MINU KONTO",
        wholesale: "HULGIMÜÜK",
        cards: "KAARDID",
        invoicing: "ARVELDUS",
        invoicingMobile: "ARVELDUS",
        invoicesMobile: "ARVED",
        manage: "HALDUS",
        virtualFamily: "VIRTUAALPERE",
        vouchers: "VAUTŠERID",
        transactions: "TEHINGUD",
        idCredit: "ID-KREDIIT",
        electricity: "ELEKTER",
        map: "TANKLAD",
        userControls: {
            language: {
                et: "Eesti keel",
                en: "English",
                ru: "Русский"
            },
            settings: "Konto seaded",
            roleChange: "Rollivahetus",
            logout: "Logi välja"
        }
    },
    roleChange: {
        title: "Rollivahetus",
        privateClient: "Eraisik",
        businessClient: "Äriklient"
    },
    languageChange: {
        title: "Keelevahetus",
        language: {
            et: "Eesti keel",
            en: "English",
            ru: "Русский"
        },
    },
    overview: {
        personCards: {
            title: "Minu sooduskaardid",
            editButton: "Muuda",
            deleteCard: {
                title: "Kustuta sooduskaart",
                content: (card) => `Olete kindel, et soovite kustutada sooduskaardi ${card}?`,
                deleteButton: "Kustuta"
            }
        },
        creditCards: {
            title: "Minu maksekaardid",
            editButton: "Muuda",
            blockUnblockCard: {
                blockButton: "Blokeeri",
                unblockButton: "Ava"
            },
            changePin: {
                title: "Muuda PIN kood",
                enterPin: 'Sisesta PIN kood',
                enterPinAgain: 'Sisesta PIN kood uuesti',
                pinNotEqual: 'PIN kood ei ühti',
                pinTooShort: 'PIN kood peab koosnema neljast numbrit',
                confirmButton: "Muuda",
            }
        },
        olerexPlus: {
            title: "Olerex Pluss",
            showAllButton: "Vaata kõiki",
            item: {
                nextFree: "Järgmine tasuta"
            },
            modal: {
                title: "Sinu Olerex Pluss",
                text: "Iga 6. Sinu lemmik kuum jook, saiake, soe toit ja autopesu tasuta! Kuues tasuta toode ilmub Sinu lojaalsusprogrammi ~ ühe tunni jooksul. Olerexil on õigus muuta lojaalsusprogrammi kuuluvaid tooteid. Lojaalsusprogrammist välja läinud tooted asendatakse uue tootega ning eemaldatud toote progress kajastub analoogse toote progressi all."
            }
        },
        topAd: {
            readMoreButton: "Loe lähemalt"
        },
        userDetails: {
            title: "Minu andmed",
            editButton: "Muuda",
            userCard: {
                emailVerified: "Email kinnitatud",
                emailUnverified: "Email kinnitamata"
            },
            subscriptionsCard: {
                emailNewsletter: "Soovin saada Olerexi uudiskirja e-maili teel",
                emailNewsletterMobile: "Olerexi uudiskiri e-mailile",
                emailOffers: "Soovin saada personaalseid sooduspakkumisi e-maili teel",
                emailOffersMobile: "Sooduspakkumised e-mailile",
                smsOffers: "Soovin saada personaalseid sooduspakkumisi SMS-i teel",
                smsOffersMobile: "Sooduspakkumised SMS-i teel",
            },
            editProfileCard: {
                firstNameField: "Eesnimi",
                lastNameField: "Perenimi",
                personalCodeField: "Isikukood",
                requiredErrorLabel: 'Kohustuslik'
            },
            editContactCard: {
                emailField: "E-mail",
                phoneField: "Telefoninumber",
                requiredErrorLabel: 'Kohustuslik'
            },
            editPasswordCard: {
                usernameField: "Kasutajanimi",
                passwordField: "Parool",
                passwordRepeatField: "Parool uuesti",
                requiredErrorLabel: 'Paroolid ei kattu'
            },
            editSubscriptionsAndActionsCard: {
                emailNewsletter: "Soovin saada Olerexi uudiskirja e-maili teel",
                emailNewsletterMobile: "Olerexi uudiskiri e-mailile",
                emailOffers: "Soovin saada personaalseid sooduspakkumisi e-maili teel",
                emailOffersMobile: "Sooduspakkumised e-mailile",
                smsOffers: "Soovin saada personaalseid sooduspakkumisi SMS-i teel",
                smsOffersMobile: "Sooduspakkumised SMS-i teel",
                cancelButton: "Tühista",
                saveButton: "Salvesta muudatused"
            },
            privacyPolicyLink: "Isikuandmete töötlemise põhimõtted",
            privacyPolicyUrl: "https://olerex.ee/isikuandmete-tootlemise-pohimotted"
        },
        fuelUsage: {
            title: "Minu kütuse tarbimine",
            months: ["JAANUAR", "VEEBRUAR", "MÄRTS", "APRILL", "MAI", "JUUNI", "JUULI", "AUGUST", "SEPTEMBER", "OKTOOBER", "NOVEMBER", "DETSEMBER"],
        },
        fuelPrices: {
            title: "Tänased kütusehinnad",
            askPriceModal: {
                cancelButton: "Tühista"
            }
        },
        cards: {
            title: "Kaardid",
            showAllButton: "Vaata kõiki"
        },
        bills: {
            title: "Viimased arved",
            showAllButton: "Vaata kõiki"
        }
    },
    transactions: {
        title: "Tehingud",
        table: {
            cardColumn: "Kaart",
            productColumn: "Kaup",
            stationColumn: "Tankla",
            timeColumn: "Aeg",
            quantityColumn: "Kogus",
            sumColumn: "Summa km-ta",
            sumVatColumn: "Summa km-ga"
        },
        noContent: "Tehinguid ei leitud"
    },
    vouchers: {
        title: "Vautšerid",
        item: {
            validTo: (to) => `Kehtib kuni ${to}`
        },
        email: {
            title: "Emaili see vautšer",
            emailField: "Saaja e-mail",
            sendButton: "Saada"
        },
        noContent: "Vautšereid ei leitud"
    },
    idCredit: {
        title: "Krediitkaart",
        invoicesTab: "Arved",
        incomingsTab: "Laekumised",
        headerStats: {
            unpaidTotal: "Tasumisele kuulub",
            overDeadline: "Üle tähtaja",
            balance: "Saldo",
            remainingLimit: "Jääk",
            referenceNumber: "Viitenumber"
        },
        content: {
            invoices: "Arved",
            incomings: "Laekumised",
            total: (total) => `Kokku ${total}`,
            receivedItem: {
                type: "Laekumine",
                sum: "Laekunud summa",
                sumMobile: "SUMMA",
                date: "Laekumise kuupäev"
            },
            billItem: {
                total: "Tasumisele kuulub",
                totalMobile: "Summa",
                date: "Arve kuupäev",
                dateMobile: "Kuupäev",
                paymentDate: "Makse kuupäev",
                paymentDateMobile: "Tähtaeg",
                paid: "Arvest tasutud",
                payButton: "Arvet tasuma"
            }
        },
        noContent: "Arveid ei LEITUD"
    },
    electricity: {
        title: 'Elekter',
        createContract: 'Sõlmi leping',
        eurosMonth: '€ / kuu',
        centsKwh: 'senti / kWh',
        cents: 's',
        duringDay: 'päeval',
        atNight: 'öösel',
        period: (period) => {
            if (period.type === 'month') {
                return period.value > 1 ? 'kuud' : 'kuu'
            }
            return period.value > 1 ? 'aastat' : 'aasta'
        },
        meteringPointModal: {
            selectMeteringPoint: 'Vali mõõtepunkt',
            selectPackage: 'Vali pakett',
            search: 'Otsi...',
            meteringPointCode: 'Mõõtepunkti kood',
            selectAll: 'Vali kõik',
            next: 'Edasi',
            pointsSelected: (count) => count + (count === 1 ? ' mõõtepunkt' : ' mõõtepunkti') + ' valitud'
        },
        package: 'Pakett',
        packagePrice: 'Paketi hind',
        contractBeginning: 'Lepingu algus',
        contractEnd: 'Lepingu lõpp',
        meteringPoint: "mõõtepunkt",
        meteringPoints: "mõõtepunkti",
        consumptionAddress: "Tarbimiskoha aadress",
        relatedContract: 'Seotud leping',
        meteringPointCode: 'Mõõtepunkti kood',
        tabs: {
            contractsTab: 'Lepingud',
            meteringPointsTab: 'Mõõtepunktid',
            consumptionTab: 'Tarbimus'
        },
        filter: 'Filtreeri',
        boundByContract: 'Lepinguga seotud',
        contractElsewhere: 'Leping mujal',
        newContract: 'Uus leping',
        bringOver: 'Too üle',
        editContract: 'Muuda lepingut',
        endContract: 'Lõpeta leping',
        iAgree: "Nõustun",
        withContract: "Lepinguga",
        toSignPage: "Allkirjastama",
        contractPreviewTitle: "Lepingu eelvaade",
        endContractTitle: "Lepingu lõpetamine",
        endContractCheckbox: (contracts) => 'Saada ' + contracts + (contracts === 1 ? ' leping' : ' lepingut') + ' kliendihaldurile lõpetamiseks',
        confirm: "Kinnita"
    },
    cards: {
        noCards: "Kaarte ei leitud.",
        grouped: "Grupeeritult",
        ungrouped: "Grupeerimata",
        orderBy: "Järjesta",
        header: {
            title: "Kaardid",
            selectedCards: "Valitud kaardid:",
            totalUsed: "Kokku kasutatud:",
            businessLimit: "Ettevõtte limiit:",
            newCardButton: "Uus kaart",
            searchPlaceholder: "OTSI...",
            errorModalTitle: "Viga!"
        },
        cardItem: {
            users: n => `${n} kasutajat`,
            expiry: "Kehtib kuni",
            limit: "Kaardi limiit",
            unused: "Kasutamata",
            used: "Kasutatud",
            limitDropdown: "Limiit",
            allowedToBuy: "Lubatud osta",
            param: "Tankimise parameeter",
            editNameButton: "Muuda nime",
            editLimitButton: "Muuda limiite",
            extendButton: "Asenda aeguv",
            blockButton: "Blokeeri",
            unblockButton: "Ava kaart",
            addParamButton: "Lisa parameeter",
            editParamButton: "Muuda parameetrit",
            activateButton: "Aktiveeri",
        },
        footer: {
            selectedCard: "kaart valitud",
            selectedCards: "kaarti valitud",
            extendButton: "Asenda aeguv",
            editLimitButton: "Muuda limiite",
            blockButton: "Blokeeri",
            unblockButton: "Ava",
            activateButton: "Aktiveeri",
        },
        cardUsersModal: {
            title: 'Kaardi kasutajad',
            close: 'Sulge',
            remove: 'Eemalda',
            empty: 'Valitud kaardiga seotud kasutajad puuduvad',
            confirmRemove: name => `Kas oled kindel, et soovid kasutaja ${name} kaardilt eemaldada?`,
            blockAddingApp: 'Keela uute kasutajate sidumine valitud kaardiga',
        },
        editCardNameModal: {
            title: "Muuda kaardi nime",
            nameField: "Kaardi nimi",
            saveButton: "Muuda nimi"
        },
        editCardLimitsModal: {
            title: "Muuda kaardi limiite",
            limitField: "Kaardi limiit",
            dayLimitField: "Päevalimiit",
            fuelField: "Lubatud kütused",
            fuel1: "Ainult diisel",
            fuel2: "Kõik kütused",
            productField: "Lubatud kaubad",
            product1: "Kaubad keelatud",
            product2: "Ainult autokaubad",
            product3: "Kõik kaubad",
            saveButton: "Salvesta"
        },
        activateCardModal: {
            title: 'Aktiveeri kaart',
            activateButton: 'Aktiveeri kaart',
        },
        blockCardModal: {
            title: "Blokeeri kaart",
            contentBlockCard: (name) => `Kas te olete kindel, et soovite blokeerida kaardi nimega ${name}?`,
            contentBlockCards: (count) => `Kas te olete kindel, et soovite blokeerida valitud kaardid (${count})?`,
            reasonField: "Blokeerimise põhjus",
            blockButton: "Blokeeri kaart"
        },
        unblockCardModal: {
            title: "Ava kaart",
            contentUnblockCard: (name) => `Kas te olete kindel, et soovite avada kaardi nimega ${name}?`,
            contentUnblockCards: (count) => `Kas te olete kindel, et soovite avada valitud kaardid (${count})?`,
            blockButton: "Ava kaart"
        },
        extendCardModal: {
            title: "Asenda aeguv kaart",
            contentExtendCard: (name) => `Kas te olete kindel, et soovite asendada aeguvat kaarti nimega ${name}?`,
            contentExtendCards: (count) => `Kas te olete kindel, et soovite asendada valitud aeguvad kaardid (${count})?`,
            extendButton: "Pikenda kaart"
        },
        orderCard: {
            orderCards: "Telli uus kaart",
            cardsOrdered: "Kaardid tellitud",
            nameField: "Kaardi nimi",
            limitField: "Kaardi limiit",
            fuelField: "Lubatud kütused",
            fuel1: "Ainult diisel",
            fuel2: "Kõik kütused",
            productField: "Lubatud kaubad",
            product1: "Kaubad keelatud",
            product2: "Ainult autokaubad",
            product3: "Kõik kaubad",
            maxLength: (length, maxLength) => `${length} / ${maxLength} tähemärki`,
            perMonthLimit: "€ kuus",
            perDayLimit: "€ päevas",
            page1: {
                topText: "Kaardi nime väljale võid lisada töötaja nime, autonumbri jne.",
                fuel1: "Ainult diisel",
                fuel2: "Kõik kütused",
                productField: "Lubatud kaubad",
                product1: "Kaubad keelatud",
                product2: "Ainult autokaubad",
                product3: "Kõik kaubad",
                addCardButton: "Lisa kaart",
                nextButton: "Edasi"
            },
            page2: {
                topText: "Palun täpsustage kaardi vastuvõtja andmed.",
                pickupField: "Kaardi vastuvõtja",
                pickupPersonalCode: "Vastuvõtja isikukood",
                pickupEmail: "Vastuvõtja email",
                pickupLocation: "Kaardi kättesaamise asukoht",
                locationInfo: "Asukoha saate valida ka kaardi pealt tankla peale klikkides.",
                backButton: "Tagasi",
                orderButton: "Telli kaart"
            },
            page3: {
                closeButton: "Sulge"
            }
        },
        editParamsModal: {
            titleAdd: "LISA TANKIMISE PARAMEETER",
            titleEdit: "MUUDA TANKIMISE PARAMEETRIT",
            paramField: "Tankimise parameeter",
            editButton: "Salvesta parameeter",
        }
    },
    invoicing: {
        title: "Arveldus",
        invoicingTab: "Tehingud",
        invoicesTab: "Arved & laekumised",
        incomingsTabMobile: "Laekumised",
        invoicesTabMobile: "Arved",
        searchPlaceholder: "OTSI...",
        invoices: {
            noInvoices: "Arved puuduvad.",
            noIncomings: "Laekumised puuduvad.",
            noContent: "Arveid ei LEITUD",
            termsOfSaleLink: "Müügitingimused",
            termsOfSaleUrl: "https://olerex.ee/olxweb/documents/Terms_of_Sale_Olerex_et.pdf"
        },
        transactions: {
            noTransactions: "Tehinguid ei leitud"
        }
    },
    manage: {
        header: {
            title: "Haldus",
            addressTab: "Aadress",
            contactsTab: "Kontaktisikud",
            usersTab: "Kasutajad",
            managerTab: "Kliendihaldur",
            servicesTab: "Lisateenused"
        },
        modal: {
            backButton: "Tagasi",
            closeButton: "Sulge",
            maxLength: (length, maxLength) => `${length} / ${maxLength} tähemärki`,
            perMonthLimit: "€ kuus",
            perDayLimit: "€ päevas",
            companyLimit: (limit) => `ettevõtte limiit: ${limit} €`
        },
        address: {
            title: "Aadress",
            editButton: "Muuda",
            editAddressModal: {
                title: "Muuda aadressi",
                addressField: "Aadress",
                saveButton: "Salvesta aadress"
            }
        },
        contacts: {
            title: "Kontaktisikud",
            addButton: "Lisa",
            addContactModal: {
                title: "Lisa kontakt",
                nameField: "Nimi",
                emailField: "E-mail",
                phoneField: "Telefoninumber",
                saveButton: "Lisa kontakt"
            },
            editContactModal: {
                title: "Muuda kontakti",
                nameField: "Nimi",
                emailField: "E-mail",
                phoneField: "Telefoninumber",
                saveButton: "Salvesta kontakt"
            },
            deleteContactModal: {
                title: "Kustuta kontakt",
                content: (name) => `Kas olete kindel, et soovite kustutada kontakti nimega ${name}? Kustutatud kontakti ei ole võimalik taastada.`,
                deleteButton: "Kustuta kontakt"
            }
        },
        users: {
            title: "Kasutajad",
            addButton: "Lisa",
            table: {
                nameColumn: "Nimi",
                personalCodeColumn: "Isikukood",
                emailColumn: "E-mail",
                roleColumn: "Roll"
            },
            addUserModal: {
                title: "Lisa kasutaja",
                nameField: "Nimi",
                emailField: "E-mail",
                roleField: "Kasutaja roll",
                role1: "Tavakasutaja",
                role2: "Administraator",
                role3: "Juhatus / Tegevjuht",
                personalCodeField: "Isikukood",
                saveButton: "Lisa kasutaja"
            },
            editUserModal: {
                title: "Muuda kasutajat",
                nameField: "Nimi",
                emailField: "E-mail",
                roleField: "Kasutaja roll",
                role1: "Tavakasutaja",
                role2: "Administraator",
                role3: "Juhatus / Tegevjuht",
                personalCodeField: "Isikukood",
                saveButton: "Salvesta kasutaja"
            },
            deleteUsersModal: {
                title: "Kustuta kasutaja",
                content: (name) => `Kas olete kindel, et soovite kustutada kasutaja nimega ${name}? Kustutatud kasutajat ei ole võimalik taastada.`,
                deleteButton: "Kustuta kasutaja"
            }
        },
        manager: {
            title: "Minu kliendihaldur",
            sendEmail: "Saada email",
            call: "Helista",
            hours: "8:00 - 17:00",
            weekdays: "Tööpäeviti"
        },
        services: {
            title: "Lisateenused",
            activated: "Aktiveeritud",
            notActivated: "Aktiveerimata",
            activate: "Aktiveeri",
            deactivate: "Eemalda",
            editActivationModal: {
                activateButton: "Aktiveeri",
                deactivateButton: "Deaktiveeri"
            }
        }
    },
    wholesale: {
        header: {
            title: 'Hulgimüük',
            calculatorTab: 'Hinnakalkulaator',
            orderHistoryTab: 'Tellimuste ajalugu'
        },
        manager: {
            title: 'Minu kliendihaldur',
            sendEmail: 'Saada meil',
            call: 'Helista',
            hours: '8:00–17:00',
            weekdays: 'Tööpäeviti'
        },
        editAddressModal: {
            title: 'Tarne asukoht',
            address: 'Aadress',
            closeButton: 'Tagasi',
            saveButton: 'Salvesta aadress',
        },
        orderHistory: {
            title: 'Tellimuste ajalugu',
            table: {
                fuelColumn: 'Kütus',
                addressColumn: 'Aadress',
                commentColumn: 'Kommentaar',
                timeColumn: 'Aeg',
                statusColumn: 'Staatus',
                quantityColumn: 'Kogus',
                sumColumn: 'Summa km-ta',
                sumVatColumn: 'Summa km-ga'
            },
            noContent: 'Tellimusi ei leitud'
        },
        calculator: {
            fuelSection: 'Vali kütuse liik',
            vehicleSection: 'Kütuseveoki tüüp',
            timeSection: 'Kohaletoimetamise ajavahemik',
            quantitySection: 'Kogus liitrites',
            periodSection: 'Tarneperiood',
            addressSection: 'Tarne asukoht',
            commentSection: 'Kommentaar',
            smallVehicleInfo: 'Kütuseveok (täismass 20t) pääseb rohkematesse kohtadesse aga saab väiksemaid koguseid viia.',
            bigVehicleInfo: 'Kütuseveok (täismass 36t) pääseb rohkematesse kohtadesse ja saab suuremaid koguseid viia.',
            mtf: 'E-R',
            mts: 'E-P',
            withinDays: '% päeva jooksul',
            change: 'Muuda',
            typeHere: 'Kirjuta siia',
            balance: 'Vaba jääk',
            priceForYouIs: 'Hinnakalkulaatori parameetrite järgi on hind sulle',
            includingVatAndTransport: 'hind koos käibemaksu ja transpordiga',
            sendOrder: 'Esita tellimus',
            priceInfo: 'Hinna arvutamisel võetakse arvesse kütuse liiki, kütuseveoki tüüpi, liitreid, tarneperioodi ja asukohta ja kohaletoimetamise aega.',
            personalOfferInfo: 'Personaalse pakkumise soovil edastatakse teie hinnakalkulaatori päring Olerex kliendihaldurile, kes teiega ühendust võtab.',
            wantPersonalOffer: 'Soovin personaalset pakkumist',
            contactViaPhone: 'Võta ühendust tel teel',
            contactViaEmail: 'Võta ühendust e-maili teel',
            orderSubmitted: 'Tellimus esitatud',
            personalOfferSubmitted: 'Pakkumise soov edastatud',
            error: 'Viga',
            success: 'Esitatud',
            missingAddress: 'Palun määra tarne asukoht',
            missingContactPreference: 'Palun määra kuidas soovid, et teiega ühendust võetakse',
        },
    },
    virtualFamily: {
        title: "Virtuaalpere",
        newVirtualFamilyButton: "Uus virtuaalpere",
        membersTab: "Liikmed",
        invitesTab: "Kutsed",
        header: {
            newLinkButton: "Uus link",
            sendInvitationsButton: "Saada kutsed",
            addMembersButton: "Lisa liikmed",
            top1: "Perega liitunud",
            top2: "Aktiivsed sel kuul",
            top3: "Teenitud soodustus"
        },
        downloadExcel: "Lae alla exceli failina",
        families: {
            noContent: "Virtuaalperesid ei ole",
            familyModal: {
                titleNew: "Loo uus virtuaalpere",
                titleEdit: "Muuda virtuaalpere nime",
                text: "Pere nimi",
                saveButton: "Salvesta",
                createButton: "Loo"
            }
        },
        members: {
            table: {
                nameColumn: "Nimi",
                personalCodeColumn: "Isikukood",
                emailColumn: "E-mail",
                statusColumn: "Liikmelisus"
            },
            noContent: "Virtuaalperes liikmeid ei ole.",
            bottomBar: {
                memberSelected: (count) => `${count} liige valitud`,
                membersSelected: (count) => `${count} liiget valitud`,
                closeButton: "Sulge",
                deactivateMember: "Deaktiveeri valitud",
            },
            deactiveModal: {
                title: "Deaktiveeri valitud",
                text: "Deaktiveerimisega eemaldatakse valitud kasutaja(d) virtuaalperest.",
                deactivateButton: "Deaktiveeri"
            }
        },
        links: {
            table: {
                linkColumn: "Kutse link",
                expiryColumn: "Kehtib kuni",
                descriptionColumn: "Kirjeldus",
                statusColumn: "Staatus",
                expiredButton: "Aegunud",
                pauseButton: "Peata",
                activateButton: "Aktiveeri",
                copyButton: "Kopeeri",
                editButton: "Muuda",
                copiedToClipboard: "Kopeeritud lõikelauale"
            },
            noContent: "Virtuaalperel pole kutseid.",
            toggleLinkModal: {
                deactivateTitle: "Deaktiveeri kutse",
                deactivateText: "Kutse deaktiveeritakse.",
                deactivateButton: "Deaktiveeri",
                activateTitle: "Aktiveeri kutse",
                activateTet: "Kutse aktiveeritakse.",
                activateButton: "Aktiveeri"
            },
            linkModal: {
                titleNew: "Uus link",
                titleEdit: "Muuda linki",
                descriptionText: "Lingi kirjeldus",
                expiresAtText: "Aegub",
                expiresInDay: (days) => `${days} päeva pärast`,
                expiresInDays: (days) => `${days} päeva pärast`,
                saveButton: "Salvesta",
                createButton: "Loo"
            }
        }
    },
    general: {
      downloadFile: 'Lae fail alla'
    }
};
