import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Loader from '../../../components/Loader';
import ContentContainer from '../../../components/ContentContainer';
import { privateDashboardRequest } from '../../../../../Networking';
import { Modal, ModalText } from "../../../business/manage/components/Modal";
import './Cards.scss';
import IconModalCross16px from "../../../assets/Icon_Modal-Cross_Black_16px.svg";
import {Environment} from "../../../../../Utils";

const DeleteCard = connect(
    state => ({
        translation: state.translation.overview.personCards.deleteCard,
        login: state.login
    }),
    dispatch => ({ dashboardRequest: (body, success, error) => privateDashboardRequest(dispatch, body, success, error) }))(
        class extends PureComponent {
            constructor(props) {
                super(props);
                this.state = { isLoaderVisible: false, isDone: false, isError: false, result: null };
            }

            deleteCard = (id) => {
                const userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
                if (!userId) {
                    return; //not in private role
                }
                if (this.state.isLoaderVisible) {
                    return;
                }
                this.setState({ isLoaderVisible: true }, async () => {
                    // await new Promise(x => setTimeout(x, 500000));
                    this.props.dashboardRequest(
                        {
                            "action": "deleteDiscountCard",
                            "userId": userId,
                            "token": this.props.login.token,
                            "discountCardId": id
                        },
                        data => {
                            const _userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
                            if (!_userId || _userId !== userId) {
                                return; //role changed
                            }
                            this.setState({
                                result: data.records.result,
                                isDone: data.records.statusCode === 200,
                                isError: data.records.statusCode !== 200,
                                isLoaderVisible: false
                            });
                        }, error => {
                            this.setState({ isLoaderVisible: false });
                        });
                })
            }

            render() {
                return (
                    <Modal {...{
                        title: this.props.translation.title,
                        onCloseButtonClick: this.props.onModalClose,
                        content: <ModalText {...{ text: this.props.translation.content(this.props.cardNumber) }} />,
                        isSaveButton: false,
                        onActionButtonClick: () => this.deleteCard(this.props.discountCardId),
                        actionButtonTitle: this.props.translation.deleteButton,
                        isLoaderVisible: this.state.isLoaderVisible,
                        isDone: this.state.isDone,
                        isError: this.state.isError,
                        result: this.state.result,
                        height: 112,
                    }} />
                )
            }
        });

const CardItem = React.memo(props =>
    <div className="person-overview-cards_collection_item">
        <img className="person-overview-cards_collection_item_card-img img1" src={props.img1} alt="" />
        <img className="person-overview-cards_collection_item_card-img img2" src={props.img2} alt="" />
        <p className="person-overview-cards_collection_item_card-number">{props.cardNumber}</p>
        <p className="person-overview-cards_collection_item_text">{props.created}</p>
        {props.onDeleteButtonClick && (
            <button className="person-overview-cards_collection_item_delete-button" onClick={props.onDeleteButtonClick}>
                <img className="person-overview-cards_collection_item_delete-button_img" src={IconModalCross16px} alt="delete" />
            </button>
        )}
    </div>
);


const Cards = React.memo((props) => {
    const onEditingToggle = () => {
        window.webViewBridge.send('showCardsWindow', {'environment': 'self_service', 'type': 'discount'});
    };

    const containerProps = {};

    if (props.login?.environmentId === Environment.OlxApp) {
        containerProps.action = (
            <button className={`user-details_edit-button`} onClick={onEditingToggle}>
                {props.translation.editButton}
            </button>
        );
    }

    return (
        <ContentContainer className="person-overview-cards" {...{
            ...containerProps,
            title: (
                <div className="person-overview-cards_title">
                    {props.translation.title}
                </div>
            ),
            content: (
                <div className="person-overview-cards_content">
                    <TransitionGroup component={null}>
                        {props.isLoaderVisible && (
                            <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                <div className="person-overview-cards_loader_container">
                                    <Loader {...{ className: "person-overview-cards_loader" }} />
                                </div>
                            </CSSTransition>
                        )}
                        {!props.isLoaderVisible && (
                            <CSSTransition key={"content-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                <div className="person-overview-cards_collection">
                                    {props.discountCards && props.discountCards.map((item, index) => (
                                        <CardItem {...{
                                            ...item,
                                            key: index,
                                            onDeleteButtonClick: item.canBeRemoved ?
                                                (() => props.onModalOpen(
                                                    <DeleteCard {...{ ...item, onModalClose: props.onModalClose }} />
                                                )) :
                                                null,
                                        }} />
                                    ))}
                                </div>
                            </CSSTransition>
                        )}
                    </TransitionGroup>
                </div>
            ),
        }} />
    );
});

export default connect(state => ({ translation: state.translation.overview.personCards, login: state.login,
}))(Cards);
